import { EditSubscriptionPageForm } from './edit-subscription-page-form/edit-subscription-page-form';

interface EditSubscriptionsPageOpts {
    form: string;
    errorSelector: string;
    resultSelector: string;
    resetChangesSelector: string;
    loaderSelector: string;
    clearVariantsAttr: string;
    pointAttr: string;
    topicAttr: string;

    contentWrapperSelector: string;
    emptyWrapperSelector: string;
}

export class EditSubscriptionsPage {
    form: string;
    errorSelector: string;
    resultSelector: string;
    resetChangesSelector: string;
    loaderSelector: string;
    clearVariantsAttr: string;
    pointAttr: string;
    topicAttr: string;

    contentWrap: HTMLDivElement | null;
    emptyWrap: HTMLDivElement | null;

    email: string | null;

    constructor(opts: EditSubscriptionsPageOpts) {
        this.form = opts.form;
        this.errorSelector = opts.errorSelector;
        this.resultSelector = opts.resultSelector;
        this.resetChangesSelector = opts.resetChangesSelector;
        this.loaderSelector = opts.loaderSelector;
        this.clearVariantsAttr = opts.clearVariantsAttr;
        this.pointAttr = opts.pointAttr;
        this.topicAttr = opts.topicAttr;

        this.contentWrap = document.querySelector<HTMLDivElement>(opts.contentWrapperSelector);
        this.emptyWrap = document.querySelector<HTMLDivElement>(opts.emptyWrapperSelector);

        const searchParam = new URLSearchParams(window.location.search);

        this.email = searchParam.get('email');

        if (this.email) {
            this.openForm();
            this.initForm();
        } else {
            this.hiddenForm();
            this.openEmptyContent();
        }
    }

    openEmptyContent() {
        if (! this.emptyWrap) {
            return;
        }

        this.emptyWrap.style.display = 'block';
    }

    openForm() {
        if (! this.contentWrap) {
            return;
        }

        this.contentWrap.style.display = 'block';
    }

    hiddenForm() {
        if (! this.contentWrap) {
            return;
        }

        this.contentWrap.style.display = 'none';
    }

    initForm() {
        if (! this.email) {
            return;
        }

        // eslint-disable-next-line
        new EditSubscriptionPageForm({
            form: this.form,
            email: this.email,
            errorSelector: this.errorSelector,
            resultSelector: this.resultSelector,
            resetChangesSelector: this.resetChangesSelector,
            loaderSelector: this.loaderSelector,
            clearVariantsAttr: this.clearVariantsAttr,
            pointAttr: this.pointAttr,
            topicAttr: this.topicAttr
        });
    }
}
